<template>
  <div class="allmsgclass">
   <div class="nomsgclass"></div>
    <p class="msg">
      <van-icon
        name="arrow-left"
        size="0.34rem"
        style="margin-left: 0.2rem"
        @click="concel"
      />
      <span class="wan">选择岗位</span>
    </p>
    <div class="msgclass">请选择所要添加的岗位名称</div>
    <!-- <div class="semsgcla">
      <input
        readonly="readonly"
        placeholder="请输入你要搜索的技能"
        type="text"
      />
      <van-icon color="#FF4800" size=".38rem" name="search" />
    </div> -->
    <div class="gwlistclass">
      <div @click="clickgw(item)" class="gw2class" v-for="(item,index) in list1" v-bind:key="index">{{ item.position_name }}</div>
    </div>
    <van-popup v-model="show" position="right" :style="{ width: '85%',height:'100%' }">
      <div class="box">
        <!-- <div class="nomsgclass"></div>
        <p class="msg">
          <van-icon
            name="arrow-left"
            size="0.34rem"
            style="margin-left: 0.2rem"
            @click="concel"
          />
          <span class="wan">选择职位</span>
        </p> -->
        <!-- <ul class="ul">
          <li v-for="(item, index) in list" :key="index" class="lis">
            {{ item.position_name }}
          </li>
        </ul> -->
        <div class="wrapper">
          <div class="left">
            <ul>
              <li
                v-for="(item, index) in list"
                :class="{ active: index === indexs }"
                :key="index"
                class="lis"
                @click="qh(index, item)"
              >
                {{ item.position_name }}
              </li>
            </ul>
          </div>
          <div class="right">
            <ul>
              <li
                v-for="(item, index) in lists"
                :key="index"
                @click="confirm(item.position_name)"
              >
                {{ item.position_name }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>
<script>
import {Toast} from "vant"
export default {
  data() {
    return {
      activeKey: 0,
      list: [],
      lists: [],
      indexs: 0,
      aaa: "",
      show:false,
      list1:[],
      msg1:"",
      msg2:"",
      msg3:""
    };
  },
  created() {
    Toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: "",
      });
    this.$http
      .post("/firm/v1/Position/positionList", {
        requestParams: {
          reqType: "majors_index",
        },
      })
      .then((res) => {
        Toast.clear();
        res = JSON.parse(res.data);
        console.log(res);
        this.list1 = res.data.positionList;
        this.lists = this.list[0].children;
      })
      .catch(() => {});
  },
  methods: {
    qh(index, item) {
      this.indexs = index;
      this.lists = item.children;
      this.msg2 = item.position_name; //回传数据
    },
    confirm(name) {
      this.msg3 = name;
      this.$emit("config", this.msg1+"/"+this.msg2+"/"+this.msg3);
      this.show = false;
    },
    concel() {
      this.$router.go(-1);
    },
    clickgw(item){
        this.show = true;
        this.list = item.children;
        this.msg1 = item.position_name; //回传数据
        this.lists = item.children[0].children;
        this.msg2 = item.children[0].position_name; //回传数据
    }
  },
};
</script>
<style scoped>
.gw2class {
  padding: 0.2rem;
}
.gwlistclass {
  margin: 0.29rem 0.48rem;
  font-size: 0.3rem;
}
.gwlistclass > :nth-child(odd) {
  background-color: #f7f8fa;
}
.msgclass {
  margin-top: 0.47rem;
  margin-left: 0.48rem;
  font-size: 0.42rem;
  line-height: 0.48rem;
  color: #333333;
  font-weight: bold;
}
@media only screen and (min-height: 800px) {
  .allmsgclass >>> .van-tree-select {
    height: 11.5rem !important;
  }
}
@media only screen and (max-height: 800px) {
  .allmsgclass >>> .van-tree-select {
    height: 8.5rem !important;
  }
}
.semsgcla {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0.29rem 0.48rem;
  padding: 0.11rem 0.32rem;
  border: 1px solid #ff4800;
  border-radius: 0.06rem;
}
.semsgcla input {
  border: none;
  font-size: 0.28rem;
  line-height: 0.48rem;
}

.nomsgclass {
  height: 0.39rem;
}
.msg {
  height: 0.88rem;
  line-height: 0.88rem;
  line-height: 0.48rem;
  border-bottom: solid 0.01rem #eeeeee;
}

.msg span {
  font-size: 0.34rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #222222;
}
.msg .xia {
  float: right;
  margin-top: 0.24rem;
  margin-right: 0.3rem;
}

.msg .wan {
  margin-right: 0.3rem;
  margin-left: 2.6rem;
}
.ul {
  border-right: 1px solid #ccc;
  overflow: scroll;
  height: 100%;
}
.active {
  border-left: 2px solid red !important;
}
.lis {
  padding-left: 0.3rem;
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
  font-size: 0.3rem;
  height: 0.4rem;
  line-height: 0.4rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
/* 最外侧 */
.wrapper {
  width: 7.5rem;
  height: 100%;
  /* 弹性布局 */
  display: flex;
  /* 固定定位 */
  position: fixed;
}

/* 左侧列表 */
.left {
  width: 2.5rem;
  height: 100%;
  border-right: 1px solid #ccc;
  /* 添加滚轴 */
  overflow: scroll;
}
/* 右侧列表 */
.right {
  box-sizing: border-box;

  width: 5rem;
  height: 100%;
  overflow: scroll;
}
.right ul {
  width: 100%;
  margin: 0.1rem;
}
.right ul li {
  text-align: center;
  background-color: #f6f6f6;
  margin: 0.1rem;
  height: 0.6rem;
  line-height: 0.6rem;
  width: 50%;
  font-size: 0.3rem;
  white-space: nowrap;
}
</style>