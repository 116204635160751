<template>
  <div class="allmsgclass">
    <postselect @config="theconfig"></postselect>
  </div>
</template>
<script>
import postselect from "../../components/postselect/index.vue";
import eventbus from "../../util/evenBus";
export default {
  components: {
    postselect,
  },
  data(){
    return{
      ishave:true
    }
  },
  methods:{
    theconfig(e){
      sessionStorage.setItem("item2", e);
      if(this.ishave==true){
        this.ishave = false;
        eventbus.$emit("addinfo_meth",e);
        this.$router.go(-1);
      }
    }
  },
  created() {
    
  },
  destroyed(){
    
  }
};
</script>
<style scoped>
.allmsgclass{
  background-color: #ffffff;
}
</style>